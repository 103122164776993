<template>
  <tr>
    <td>
      <strong>{{ index + 1 }}</strong>
    </td>
    <td>
      <m-input nolabel v-model="detail.PIECE" :placeholder="$t('form.unitpiece')" type="number" />
    </td>
    <td>
      <m-select nolabel :options="options.package" v-model="detail.PACKAGEID" />

    </td>
    <td>
      <m-select nolabel :options="options.cargotype" v-model="detail.CARGOTYPEID" />
    </td>
    <td>
      <b-form-select v-model="detail.BOXID" :options="options.box"></b-form-select>

    </td>
    <td>
      <m-input nolabel v-model="detail.DESI" :disabled="isDisabledDesi" :placeholder="$t('form.deci')" type="number" />
    </td>
    <td>
      <m-input nolabel v-model="detail.WEIGHT" :disabled="isDisabledDesi" :placeholder="$t('form.weight')"
        type="number" />
    </td>
    <td>
      <b-button class="btn-sm" size="sm" variant="danger" @click.prevent="removeDetail()">-
      </b-button>

    </td>
  </tr>
</template>

<script>
import config from '../../config';

export default {
  props: {
    options: {
      type: Object,
      default: () => ({})
    },
    detail: {
      type: Object,
      default: () => ({
        ID: null,
        PIECE: null,
        PACKAGEID: null,
        CARGOTYPEID: null,
        BOXID: null,
        DESI: null,
        WEIGHT: null,

        WIDTH: null,
        LENGTH: null,
        HEIGHT: null,

        BOXPRICE: 0,
        BOXGROSSAMOUNT: 0,
        BOXTAXRATE: 0,
        BOXTAXAMOUNT: 0,
        BOXNETAMOUNT: 0,

        ISREQUIREDDESI: false,

        PACKAGEPRICE: 0,
        PACKAGEGROSSAMOUNT: 0,
        PACKAGETAXRATE: 0,
        PACKAGETAXAMOUNT: 0,
        PACKAGENETAMOUNT: 0,

        GROSSAMOUNT: 0,
        TAXAMOUNT: 0,
        NETAMOUNT: 0,
      })
    },
    index: {
      type: Number,
      default: 0
    },
    lookup: {
      type: Object,
      default: () => ({})
    },
    paytypeid: {
      type: [Number, String],
      default: () => null
    }
  },
  data() {
    return {
      isDisabledDesi: false,
    }
  },
  methods: {
    removeDetail() {
      this.$emit('remove-detail', this.index);
    },
    calcPrice() {
      this.isDisabledDesi = false;
      this.detail.ISREQUIREDDESI = true;
      let desi = parseInt(this.detail.DESI);
      let weight = parseInt(this.detail.WEIGHT);
      let desiOrWeight = (desi || 0) > (weight || 0)
        ? desi || 0
        : weight || 0;

      let piece = parseInt(this.detail.PIECE) || 1;


      if (this.detail.PACKAGEID) {
        const packs = this.lookup.packageData.find((item) => {
          return item.ID == this.detail.PACKAGEID;
        });
        if (!packs) {
          return;
        }
        if (packs.ISREQUIREDDESI == "1") {
          if (desiOrWeight === 0) {
            desiOrWeight = 1;
          }
          const findedDesi = packs.desi.find((item) => {
            return (parseFloat(item.DESISTART) <= parseFloat(desiOrWeight) &&
              parseFloat(desiOrWeight) <= parseFloat(item.DESIEND));
          });
          if (!findedDesi) {
            //TODO: desi bulanamadı bilgisi ver ekrana ama burda değil
            if (packs.DYNAMICDESIUSE == "1") {
              const different = parseFloat(desiOrWeight) - parseFloat(packs.DYNAMICDESISTART);
              if (different >= 0) {
                let amount = parseFloat(packs.DYNAMICDESIPRICE);
                amount += different * parseFloat(packs.DYNAMICDESIPERPRICE);
                this.detail.PACKAGEPRICE = parseFloat(amount);
                this.detail.PACKAGETAXRATE = parseFloat(packs.desi[0].DESITAXRATE);
              }
              else {
                //TODO: desi bulanamadı bilgisi ver ekrana ama burda değil
                return;
              }
            }
            else {
              //TODO: desi bulanamadı bilgisi ver ekrana ama burda değil
              return;
            }
          }
          else {
            this.detail.PACKAGEPRICE = parseFloat(findedDesi.DESIPRICE);
            this.detail.PACKAGETAXRATE = parseFloat(findedDesi.DESITAXRATE);
          }
        }
        else {
          this.isDisabledDesi = true;
          this.detail.DESI = 0;
          this.detail.WEIGHT = 0;
          this.detail.PACKAGEPRICE = parseFloat(packs.PRICE);
          this.detail.PACKAGETAXRATE = parseFloat(packs.TAXRATE);
          this.detail.ISREQUIREDDESI = false;
        }
        /***********************************yeni ücretler****************************/

        const boxId = this.detail.BOXID;
        this.detail.BOXPRICE = 0;
        if ((parseInt(boxId) || 0) > 0) {
          const box = this.lookup.boxData.find((item) => boxId == item.ID);
          if (box) {
            this.detail.BOXPRICE = parseFloat(box.BOXPRICE);
            this.detail.BOXTAXRATE = parseFloat(box.BOXTAXRATE);
          }
        }

      } else {
        return;
      }

      if (config.USE_PIECE_MULTIPLICATION) {
        this.detail.PACKAGEGROSSAMOUNT = parseFloat(this.detail.PACKAGEPRICE) * parseFloat(piece);
        if (this.detail.BOXPRICE > 0) {
          this.detail.BOXGROSSAMOUNT = parseFloat(this.detail.BOXPRICE) * parseFloat(piece);
        }
      } else {
        this.detail.PACKAGEGROSSAMOUNT = parseFloat(this.detail.PACKAGEPRICE);
        if (this.detail.BOXPRICE > 0) {
          this.detail.BOXGROSSAMOUNT = parseFloat(this.detail.BOXPRICE);
        }
      }

      this.detail.PACKAGETAXAMOUNT = parseFloat(this.detail.PACKAGEGROSSAMOUNT * parseFloat(this.detail.PACKAGETAXRATE) / 100);
      this.detail.PACKAGENETAMOUNT = parseFloat(this.detail.PACKAGEGROSSAMOUNT + this.detail.PACKAGETAXAMOUNT);

      if (this.detail.BOXPRICE > 0) {
        this.detail.BOXTAXAMOUNT = parseFloat(this.detail.BOXGROSSAMOUNT * parseFloat(this.detail.BOXTAXRATE) / 100);
        this.detail.BOXNETAMOUNT = parseFloat(this.detail.BOXGROSSAMOUNT + this.detail.BOXTAXAMOUNT);
      }

      this.detail.GROSSAMOUNT = parseFloat(this.detail.PACKAGEGROSSAMOUNT + this.detail.BOXGROSSAMOUNT);
      this.detail.TAXAMOUNT = parseFloat(this.detail.PACKAGETAXAMOUNT) + parseFloat(this.detail.BOXTAXAMOUNT);
      this.detail.NETAMOUNT = parseFloat(this.detail.GROSSAMOUNT + this.detail.TAXAMOUNT);
    },

  },
  watch: {
    'detail.PACKAGEID'(val) {
      if (val) {
        this.calcPrice();
      }
    },
    'detail.PIECE'(val) {
      if (val) {
        this.calcPrice();
      }
    },
    'detail.WEIGHT'(val) {
      if (val) {
        this.calcPrice();
      }
    },
    'detail.BOXID'(val) {
      if (val) {
        this.calcPrice()
      }
    },
    'detail.DESI'(val) {
      if (val) {
        this.calcPrice();
      }
    },
  }
}

</script>