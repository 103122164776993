<template>
  <m-basic-form @save="save" @remove="remove" :loadingMsg="loadingMsg" :loadingShow="loadingShow" :formId="ID"
    :buttonLoading="buttonLoading">
    <div class="row">
      <div class="col-12 text-center" v-show="form.ISCANCEL == '1'">
        <strong class="text-danger" style="font-size: 16px">{{
            $t("info.cargocancelled")
        }}</strong>
      </div>
      <div class="col-12 text-center" v-show="form.ISBROKER == '1'">
        {{ $t("info.mediagecargo") }}
        <strong class="text-info" style="font-size: 16px">{{
            brokerName
        }}</strong><br />
        <strong v-if="contract.brokerFindingContract">{{
            $t("info.mediatehavecontract")
        }}</strong>
      </div>
      <!--Gönderici-->
      <div class="col-4 border-right">
        <h6 class="text-center">{{ $t("info.sender") }}</h6>
        <m-present ref="senderPresent" :isBrokerage="isBrokerage" :errorLabel="$t('info.sender')"
          @changeReseller="changeResellerSender" @changePresent="senderChangePresent" @clearForm="senderClearForm" />

        <div class="row">
          <div class="col-12">
            {{ $t("form.vehicleselect") }}
            <m-select nolabel v-model="form.PREDEFINEDVEHICLEID" :options="options.vehicles"
              langlabel="vehicleselect" />
          </div>
        </div>

        <div class="row" v-if="anotherInvoice">
          <div class="col-12">
            <b-form-checkbox v-model="form.USEANOTHERINVOICE">
              {{ $t("info.anotherinvoice") }}
            </b-form-checkbox>
          </div>
          <div class="col-12" v-show="form.USEANOTHERINVOICE">
            <multiselect :disabled="!form.USEANOTHERINVOICE" v-model="selected.anotherInvoice"
              :options="lookup.anotherInvoice" :placeholder="$t('form.anotherinvoicesearch')" label="text"
              track-by="value" :limit="3" @search-change="searchAnotherInvoice"
              :selectLabel="$t('multiselect.pressentertoselect')" :selectedLabel="$t('multiselect.selected')"
              :deselectLabel="$t('multiselect.pressentertoselect')">
              <span slot="noResult">{{ $t("multiselect.nodata") }}</span>
              <span slot="noOptions">{{ $t("multiselect.nodata") }}</span>
            </multiselect>
          </div>
        </div>




        <div class="row" style="margin-top:15px">
          <div class="col-12">
            <b-form-checkbox v-model="form.USEWAYBILL">
              {{ $t("form.usewaybill") }}
            </b-form-checkbox>
          </div>
          <div class="col-12" v-if="form.USEWAYBILL">
            {{ $t("form.waybillno") }}
            <m-input nolabel :placeholder="$t('form.waybillno')" v-model="form.WAYBILLNO" />
          </div>
        </div>

        <div style="font-size: 16px; text-align: center">
          <strong v-if="!form.USEANOTHERINVOICE && contract.senderFindingContract">{{
              $t("info.senderhavecontract")
          }}</strong>

          <strong v-if="form.USEANOTHERINVOICE && contract.anotherInvoiceFindingContract">{{
              $t("info.anotherinvoicehavecontract")
          }}</strong>
        </div>

      </div>
      <!--Alıcı-->

      <div class="col-4 border-right">
        <h6 class="text-center">{{ $t("info.taker") }}</h6>
        <m-present ref="takerPresent" :errorLabel="$t('info.taker')" @changeReseller="changeReseller"
          @changeLine="changeLine" @changePresent="takerChangePresent" @clearForm="takerClearForm" />

        <div style="font-size: 16px; text-align: center">
          <strong v-if="!form.USEANOTHERINVOICE && contract.takerFindingContract">{{
              $t("info.takerhavecontract")
          }}</strong>
        </div>

        <b-form-radio-group id="btn-radios-1" v-model="form.DELIVERYTYPE" style="display: flex" :options="[
          { text: $t('form.deliveryaddress'), value: '1' },
          { text: $t('form.deliveryagent'), value: '2' },
        ]" buttons button-variant="outline-success"></b-form-radio-group>
        <div>
          {{ $t("form.takeragent") }}
          <m-select nolabel v-if="form.DELIVERYTYPE == '2'" v-model="form.DELIVERYRESELLERID"
            :options="options.resellers" />

          <m-input v-else nolabel v-model="info.TAKERRESELLERNAME" readonly />
        </div>

        <m-textarea nolabel :placeholder="$t('form.description')" v-model="form.DESCRIPTION" />

        <b-list-group>
          <b-list-group-item style="background-color: transparent">{{ $t("form.line") }}

            <span style="float: right; font-weight: bold">{{
                form.LINENAME
            }}</span>
          </b-list-group-item>
        </b-list-group>
      </div>
      <!--Kargo Bilgileri-->

      <div class="col-4">
        <h6 class="text-center">{{ $t("form.cargoinfo") }}</h6>

        <div class="row">
          <div class="col-6">
            <m-select nolabel :options="options.paytype" v-model="form.PAYTYPEID" />
          </div>
          <div class="col-6">
            <m-select nolabel :options="options.sendingtype" v-model="form.SENDINGTYPEID" />
          </div>
        </div>


        <div class="row" v-show="activePaymentDoor">
          <div class="col-6">
            {{ $t("form.payingdooramount") }}

            <m-input nolabel :placeholder="$t('form.payingdooramount')" v-model="form.PAYMENTDOORPRICE" type="number"
              readonly />
          </div>

          <div class="col-6">
            {{ $t("form.productprice") }}
            <m-input nolabel :placeholder="$t('form.productprice')" v-model="form.PRODUCTPRICE" type="number" />
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <b-form-checkbox-group style="text-align: left" v-model="form.STATICPRICES" value="1" unchecked-value="0"
              :options="options.staticPrice" stacked>
            </b-form-checkbox-group>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <m-input-btn :placeholder="$t('form.discountcoupon')" nolabel v-model="coupon.COUPONCODE"
              :disabled="coupon.isDisabledCoupon" buttonText="Sorgula" :buttonDisabled="coupon.isDisabledCoupon"
              @btn-click="checkCouponCode" />
          </div>
        </div>

        <CargoDetail :options="options" :details="details" :paytypeid="form.PAYTYPEID" :lookup="lookup"
          @add-detail="addDetail" @remove-detail="(val) => removeDetail(val)">
        </CargoDetail>

        <div class="row">
          <div class="col-4">
            Toplam Adet
            <m-input nolabel v-model="form.PIECE" disabled type="number" />
          </div>
          <div class="col-4">
            Toplam Desi
            <m-input nolabel v-model="form.DESI" disabled type="number" />
          </div>
          <div class="col-4">
            Toplam Ağırlık
            <m-input nolabel v-model="form.WEIGHT" disabled type="number" />
          </div>
        </div>



        <div style="padding: 10px 0px" v-if="false" a-v-show="contract.senderFindingContract == '1'">
          <div class="border-bottom"></div>
          <strong>Taşıma Ücreti'ni</strong>
          <b-form-radio-group id="btn-radios-1" v-model="isContractTakerPaymentType" style="display: flex" :options="[
            { text: 'Gönderici Öder', value: '0' },
            { text: 'Alıcı Öder', value: '1' },
          ]" buttons button-variant="outline-danger"></b-form-radio-group>
        </div>
        <div class="border-bottom"></div>

        <b-list-group>
          <b-list-group-item style="background-color: transparent; padding: 0.5rem 1.25rem">Taşıma Ücreti

            <span style="float: right; font-weight: bold">{{ form.PRICE }} ₺</span>
          </b-list-group-item>

          <b-list-group-item style="background-color: transparent; padding: 0.5rem 1.25rem">Ek Hizmetler

            <span style="float: right; font-weight: bold">{{ totals.ADDITIONALSERVICEPRICE }} ₺</span>
          </b-list-group-item>

          <b-list-group-item
            style="background-color: transparent; padding: 0.5rem 1.25rem">Bürüt Tutar

            <span style="float: right; font-weight: bold">{{ form.GROSSAMOUNT }} ₺</span>
          </b-list-group-item>
          <b-list-group-item
            style="background-color: transparent; padding: 0.5rem 1.25rem">
            Toplam İskonto(% {{ form.DISCOUNTRATE }})
            <span style="float: right; font-weight: bold">{{ form.DISCOUNTAMOUNT }} ₺</span>
          </b-list-group-item>
          <b-list-group-item v-if="postaServiceCostRate != 0"
            style="background-color: transparent; padding: 0.5rem 1.25rem">Ara Toplam

            <span style="float: right; font-weight: bold">{{ form.DISTANCEAMOUNT }} ₺</span>
          </b-list-group-item>
          <b-list-group-item v-if="postaServiceCostRate != 0"
            style="background-color: transparent; padding: 0.5rem 1.25rem">Pos Hiz.B.({{ postaServiceCostRate }}%)

            <span style="float: right; font-weight: bold">{{ form.POSTASERVICECOST }} ₺</span>
          </b-list-group-item>

          <b-list-group-item style="background-color: transparent; padding: 0.5rem 1.25rem">
            KDV Öncesi Tutar

            <span style="float: right; font-weight: bold">{{ form.SUBTOTAL }} ₺</span>
          </b-list-group-item>

          <b-list-group-item style="background-color: transparent; padding: 0.5rem 1.25rem">
            {{ $t("form.taxamount") }} (% {{ form.TAXRATE }})

            <span style="float: right; font-weight: bold">{{ form.TAXAMOUNT }} ₺</span>
          </b-list-group-item>

          <b-list-group-item style="background-color: transparent; padding: 0.5rem 1.25rem">Genel Toplam

            <span style="float: right; font-weight: bold; font-size: 16px">{{ totals.NETAMOUNT }} ₺</span>
          </b-list-group-item>

          <b-list-group-item variant="success"><span style="font-weight: bold; font-size: 14px">Göndericinin
              Ödeyeceği</span>

            <span style="float: right; font-weight: bold; font-size: 16px">{{ form.NETAMOUNT }} ₺</span>
          </b-list-group-item>

          <b-list-group-item v-if="form.TOTALAMOUNT > 0" variant="primary"><span
              style="font-weight: bold; font-size: 14px">Alıcının Ödeyeceği</span>

            <span style="float: right; font-weight: bold; font-size: 16px">{{ form.TOTALAMOUNT }} ₺</span>
          </b-list-group-item>
        </b-list-group>
      </div>
    </div>

    <b-modal v-model="dialog.cancelDialog" :cancel-title="$t('buton.docancel')" :ok-title="$t('dialog.cancelaction')"
      @show="resetCancelDialog" @hidden="resetCancelDialog" @ok="handleCancelDialogOk">
      <template #modal-title>{{ $t("dialog.canceltitle") }}</template>
      <div class="d-block">
        <p>{{ $t("dialog.iscancel") }}</p>
        {{ $t("dialog.cancelreason") }}
        <m-input nolabel v-model="dialog.cancelDialogVariable" :placeholder="$t('dialog.cancelreason')" />
      </div>
    </b-modal>

    <b-modal v-model="dialog.notFoundAgentDialog" :cabcel-title="$t('buton.donotfoundagent')"
      :ok-title="$t('dialog.donotfoundagentaction')" @show="resetNotFoundAgentDialog" @hidden="resetNotFoundAgentDialog"
      @ok="handleNotFoundAgentDialogOk">
      <template #modal-title>Seçilen Adreste Şube Bulunamadı!</template>
      <div class="d-block">
        <p>
          Seçilen adreste şube bulanamadı, aşağıdaki şubelerden birini yinede
          gönderim yapabilmek için kullanabilirsiniz.
        </p>

        <m-select nolabel v-model="dialog.notFoundAgentDialogVariable" :options="dialog.notFoundAgentOptions" />
      </div>
    </b-modal>
    <a :href="printerUrl" v-show="false" ref="printerBtn">Yazdır</a>
    <a :href="printerInvoiceUrl" v-show="false" ref="printerInvoiceBtn">Yazdır</a>
    <template slot="footer">
      <b-button @click="printAction" :disabled="ID == null" size="sm" squared variant="warning">{{
          $t("buton.printbarcode")
      }}</b-button>
      <b-button @click="printInvoiceAction" :disabled="ID == null" size="sm" squared variant="danger"
        v-if="getPerms('printinvoice')">{{ $t("buton.printinvoice") }}</b-button>
      <b-button @click="cancelCargo" :disabled="ID == null || form.ISCANCEL == '1'" size="sm" squared variant="info">{{
          $t("buton.docancel")
      }}</b-button>
    </template>

    <div class="logo-area">
      <a target="new" href="http://proder.com.tr"><img :src="proderLogo" class="" /></a>
      <img :src="logo" style="
          left: 0px;
          bottom: 65px;
          position: absolute;
          height: 75px;
          filter: grayscale(0%);
        " />
    </div>
  </m-basic-form>
</template>

<script>
import proderLogo from "../../assets/proder/logo.png";
import logo from "../../assets/logo.png";

import { mapGetters } from "vuex";

import api from "../../api";
import swal from "sweetalert";
import config from "../../config";
import CargoDetail from "../../components/App/CargoDetail.vue";
export default {
  mounted() {
    if (intval(this.$route.params.id) > 0) {
      this.onlyShow = true;
      this.ID = intval(this.$route.params.id);
      this.getCargo(this.ID);
    }
    if ("isbrokerage" in this.$route.params) {
      if (this.$route.params.isbrokerage == "1") {
        this.isBrokerage = true;
      }
    }
    this.getPackages();
    this.getResellerWithSendingAndPayTypeForCargo();
    this.getCargoType();
    this.getBox();
    this.getStaticPrice();
    if ("apicargoid" in this.$route.params) {
      if (intval(this.$route.params.apicargoid) > 0) {
        this.getApiCargoOne(this.$route.params.apicargoid);
        this.form.ISTRANSFER = this.$route.params.apicargoid;
      }
    }
    api.getVehicle().then((res) => {
      if (res.data.status) {
        if (res.data.data.hasOwnProperty("rows")) {
          this.options.vehicles = res.data.data.rows.map((item) => {
            return {
              text: item.VEHICLEBARCODE,
              value: item.ID,
            };
          });
        }
      }
    });
  },
  computed: {
    proderLogo() {
      return proderLogo;
    },
    logo() {
      return logo;
    },
    ...mapGetters(["getPerms"]),
    printerUrl() {
      return `prdr://invoicebarcode_temp ID=${this.ID} 0`;
    },
    printerInvoiceUrl() {
      return `prdr://invoice_temp ID=${this.ID} 0`;
    },
    insurancePay() {
      return (0).toFixed(2);
      let price = parseFloat(this.form.PRODUCTPRICE) || 0;
      if (price > 0) {
        return ((price * 2) / 100).toFixed(2);
      }
      return (0).toFixed(2);
    },
    isHaveContract() {
      if (this.contract.senderFindingContract ||
        this.contract.takerFindingContract ||
        this.contract.brokerFindingContract ||
        this.contract.anotherInvoiceFindingContract) {
        return true;
      }
      return false;
    },
  },
  data() {
    const cfg = config;
    // console.log("use_discount_all ayari: ", cfg.USE_DISCOUNT_ALL);
    return {
      onlyShow: false,
      buttonLoading: false,
      isContractTakerPaymentType: "0",
      dialog: {
        cancelDialog: false,
        cancelDialogVariable: null,
        notFoundAgentDialog: false,
        notFoundAgentDialogVariable: null,
        notFoundAgentOptions: [],
        notFoundAgentData: [],
      },
      contract: {
        senderContractQuering: false,
        takerContractQuering: false,
        senderFindingContract: false,
        takerFindingContract: false,
        senderContractData: {},
        takerContractData: {},
        brokerContractQuering: false,
        brokerFindingContract: false,
        brokerContractData: {},
        anotherInvoiceFindingContract: false,
        anotherInvoiceContractData: {},
      },
      activePaymentDoor: false,
      coupon: {
        COUPONCODE: null,
        isDisabledCoupon: false,
      },
      isDisabledDesi: false,
      isBrokerage: false,
      brokerName: null,
      ID: null,
      info: {
        TAKERRESELLERNAME: null,
        SENDERRESELLERNAME: null,
      },
      isUseDiscountAll: cfg.USE_DISCOUNT_ALL,
      postaServiceCostRate: cfg.POSTA_SERVICE_COST,
      anotherInvoice: cfg.USE_ANOTHER_INVOICE,
      selected: {
        anotherInvoice: null,
      },
      totals: {
        PACKPRICE: 0,
        TAXRATE: 0,
        PAYMENTDOORPRICE: 0,
        BOXPRICE: 0,
        ADDITIONALPAYPRICE: 0,
        INSURANCERATE: 0,
        INSURANCEPRICE: 0,
        ADDITIONALSERVICEPRICE: 0,
        GROSSPRICE: 0,
        DISCOUNTRATE: 0,
        DISCOUNTAMOUNT: 0,
        DISTANCEAMOUT: 0,
        POSTASERVICECOSTRATE: cfg.POSTA_SERVICE_COST,
        POSTASERVICECOST: 0,
        SUBAMOUNT: 0,
        TAXAMOUNT: 0,
        NETAMOUNT: 0,
        PRODUCTPRICE: 0,
        SENDERAMOUNT: 0,
        TAKERAMOUNT: 0,
      },
      details: [
        {
          ID: null,
          PIECE: null,
          PACKAGEID: null,
          CARGOTYPEID: null,
          BOXID: null,
          DESI: null,
          WEIGHT: null,

          WIDTH: null,
          LENGTH: null,
          HEIGHT: null,

          ISREQUIREDDESI: false,


          BOXPRICE: 0,
          BOXGROSSAMOUNT: 0,
          BOXTAXRATE: 0,
          BOXTAXAMOUNT: 0,
          BOXNETAMOUNT: 0,

          PACKAGEPRICE: 0,
          PACKAGEGROSSAMOUNT: 0,
          PACKAGETAXRATE: 0,
          PACKAGETAXAMOUNT: 0,
          PACKAGENETAMOUNT: 0,

          GROSSAMOUNT: 0,
          TAXAMOUNT: 0,
          NETAMOUNT: 0,
        }
      ],
      form: {
        USEANOTHERINVOICE: false,
        ANOTHERINVOICEPRESENTID: null,
        ANOTHERINVOICEPRESENTADDRESSID: null,
        USEWAYBILL: false,
        WAYBILLNO: null,
        STATICPRICES: [],
        BOXID: null,
        BOXAMOUNT: 0,
        INSURANCEPAY: 0,
        DELIVERYTYPE: "1",
        DELIVERYRESELLERID: null,
        WEIGHT: null,
        ISTRANSFER: 0,
        TRACKINGNO: null,
        ISACCEPTCOUPON: 0,
        COUPONCODE: null,
        CAMPAIGNCOUPONID: null,
        ISRETURN: 0,
        ISINFORMATIONFORM: 0,
        SENDERPRESENT: null,
        TAKERPRESENT: null,
        SENDERPRESENTADDRESS: null,
        TAKERPRESENTADDRESS: null,
        SENDINGTYPEID: null,
        PAYTYPEID: null,
        CARGOTYPEID: null,
        PRODUCTPRICE: 0,
        PAYMENTDOORPRICE: 0,
        TOTALAMOUNT: 0,
        ISBROKERAGES: 0,
        BROKERAGEID: null,
        BROKERAGEAGENTID: null,
        PACKAGEID: null,
        SENDERID: null,
        TAKERID: null,
        SENDERADDRESSID: null,
        TAKERADDRESSID: null,
        SENDERRESELLERID: null,
        TAKERRESELLERID: null,
        LINENAME: null,
        DESI: null,
        PIECE: 1,
        PRICE: null,
        GROSSAMOUNT: null,
        DISCOUNTRATE: 0,
        DISCOUNTAMOUNT: 0,
        DISTANCEAMOUNT: null,
        TAXRATE: null,
        TAXAMOUNT: null,
        SUBTOTAL: null,
        NETAMOUNT: null,
        BROKERAGEDATE: null,
        BROKERAGETIME: null,
        SAVEDATE: null,
        SAVETIME: null,
        INTEGRATIONBARCODE: null,
        BARCODE: null,
        DESCRIPTION: null,
        LASTSTATUS: null,
        LASTSTATUSDATE: null,
        LASTSTATUSTIME: null,
        ISDELIVERY: null,
        DELIVERYUSERID: null,
        DELIVERYDATE: null,
        DELIVERYTIME: null,
        DELIVEREDFULLNAME: null,
        DELIVEREDIDENTITYNO: null,
        ISTAKERCONTRACT: 0,
        ISSENDERCONTRACT: 0,
        ISBROKERCONTRACT: 0,
        ISCANCEL: 0,
        ISBROKER: 0,
        UNIQVALUE: null,
        ISANOTHERINVOICECONTRACT: 0,
        PREDEFINEDVEHICLEID: null,
        WIDTH: null,
        HEIGHT: null,
        LENGTH: null,
      },
      loadingShow: false,
      loadingMsg: null,
      options: {
        package: [
          {
            text: this.$t("form.selectpackage"),
            value: null,
            disabled: true,
          },
        ],
        paytype: [
          {
            text: this.$t("form.selectpaytype"),
            value: null,
            disabled: true,
          },
        ],
        sendingtype: [
          {
            text: this.$t("form.selectsendingtype"),
            value: null,
            disabled: true,
          },
        ],
        cargotype: [
          {
            text: this.$t("form.selectcargotype"),
            value: null,
            disabled: true,
          },
        ],
        brokerage: [],
        resellers: [
          {
            text: this.$t("form.selectreseller"),
            value: null,
          },
        ],
        box: [
          {
            text: this.$t("form.selectbox"),
            value: null,
          },
        ],
        staticPrice: [],
        vehicles: [],
      },
      lookup: {
        packageData: [],
        originalPackageData: [],
        originalSendingTypeData: [],
        sendingTypeData: [],
        payTypeData: [],
        sendingTypeTaxRate: 0,
        sendingTypePrice: 0,
        boxData: [],
        staticPrice: [],
        anotherInvoice: [],
      },
    };
  },
  methods: {
    ////////////////////////////////////////////////////////////////////////////////
    addDetail() {
      this.details.push({
        ID: null,
        PIECE: null,
        PACKAGEID: null,
        CARGOTYPEID: null,
        BOXID: null,
        DESI: null,
        WEIGHT: null,

        WIDTH: null,
        LENGTH: null,
        HEIGHT: null,

        ISREQUIREDDESI: false,

        BOXPRICE: 0,
        BOXGROSSAMOUNT: 0,
        BOXTAXRATE: 0,
        BOXTAXAMOUNT: 0,
        BOXNETAMOUNT: 0,

        PACKAGEPRICE: 0,
        PACKAGEGROSSAMOUNT: 0,
        PACKAGETAXRATE: 0,
        PACKAGETAXAMOUNT: 0,
        PACKAGENETAMOUNT: 0,

        GROSSAMOUNT: 0,
        TAXAMOUNT: 0,
        NETAMOUNT: 0,
      });
    },
    removeDetail(index) {
      this.details.splice(index, 1);
    },

    //////////////////////////////////////////////
    searchAnotherInvoice(query) {
      if (query.length < 4) {
        return;
      }
      this.getAnotherInvoiceWithTerm(query).then((res) => {
        if (res.data.status && !res.data.msg) {
          this.lookup.anotherInvoice = res.data.data.map((item) => {
            return {
              ...item,
              text: `${item.FIRMNAME ? item.FIRMNAME : ""} (${item.FULLNAME ? item.FULLNAME : ""}) [${item?.IDENTITYNO ? item?.IDENTITYNO : ""}]`,
              value: { PRESENTID: item.PRESENTID, ADDRESSID: item.ADDRESSID },
            };
          });
        }
      });
      //}, 500);
    },
    getAnotherInvoiceWithTerm(str) {
      return api.getAnotherInvoiceWithTerm(str);
    },
    /////////////////////////////////////////////
    getStaticPrice() {
      api.getStaticPrice().then((res) => {
        if (res.data.status && res.data.msg == null) {
          this.lookup.staticPrice = res.data.data.rows;
          this.options.staticPrice = res.data.data.rows.map((item) => {
            return {
              text: item.PRICENAME + " - " + item.PRICE + " ₺",
              value: item.ID,
            };
          });
        }
      });
    },
    getBox() {
      api.getBox().then((res) => {
        if (res.data.status && res.data.msg == null) {
          this.lookup.boxData = res.data.data.rows;
          this.options.box = res.data.data.rows.map((item) => {
            return {
              text: item.BOXNAME,
              value: item.ID,
            };
          });
          this.options.box.unshift({
            text: this.$t("form.selectbox"),
            value: null,
          });
        }
      });
    },
    getResellers(id) {
      api
        .getResellersWithTownId(id, this.form.ISTRANSFER)
        .then((res) => {
          if (res.data.status && !res.data.msg) {
            this.options.resellers = res.data.data.rows.map((item) => {
              return { text: item.RESELLERNAME, value: item.ID };
            });
            this.options.resellers.unshift({
              text: this.$t("form.selectreseller"),
              value: null,
              disabled: true,
            });
          }
          else {
            if (res.data.data.notFoundAgent.length) {
              this.options.resellers = res.data.data.notFoundAgent.map((item) => {
                return { text: item.RESELLERNAME, value: item.ID };
              });
            }
          }
        })
        .catch((err) => {
          swal(this.$t("dialog.systemerror"), this.$t("dialog.anerrorhasccurred") + err.toString(), "error");
        });
    },
    resetNotFoundAgentDialog() {
      this.dialog.notFoundAgentDialogVariable = null;
      // this.dialog.notFoundAgentOptions = [];
    },
    handleNotFoundAgentDialogOk() {
      if (!this.dialog.notFoundAgentDialogVariable) {
        swal("Uyarı", "Atanacak şube seçiniz!", "error");
        return;
      }
      const findedReseller = this.dialog.notFoundAgentData.find((item) => item.ID == this.dialog.notFoundAgentDialogVariable);
      if (findedReseller) {
        this.info.TAKERRESELLERNAME = findedReseller.RESELLERNAME;
        this.form.TAKERRESELLERID = findedReseller.ID;
      }
    },
    resetCancelDialog() {
      this.dialog.cancelDialogVariable = null;
    },
    handleCancelDialogOk() {
      const variable = this.dialog.cancelDialogVariable;
      if (variable != null && variable.length < 3) {
        swal(this.$t("dialog.notice"), this.$t("validate.canceldescriptionnotnull"), "error");
        return;
      }
      this.loadingMsg = this.$t("info.cancellingcargo");
      this.loadingShow = true;
      api
        .setCargoCancel({
          id: this.ID,
          variable,
        })
        .then((res) => {
          if (res.data.status) {
            swal(this.$t("dialog.success"), this.$t("dialog.cancelok"), "success");
            this.form.ISCANCEL = 1;
          }
          else {
            swal(this.$t("dialog.notice"), this.$t("dialog.anerrorhasccurred") + res.data.errMsg, "error");
          }
        })
        .catch((err) => {
          swal(this.$t("dialog.systemerror"), this.$t("dialog.anerrorhasccurred") + err.toString(), "error");
        })
        .finally(() => {
          this.loadingShow = false;
        });
    },
    cancelCargo() {
      if (!this.getPerms("cancelcargo")) {
        swal(this.$t("dialog.notice"), this.$t("dialog.noauthforcancel"), "error");
        return;
      }
      this.dialog.cancelDialog = true;
    },
    getBrokerageOne(id) {
      api
        .getBrokerageOne(id)
        .then((res) => {
          if (res.data.status && !res.data.msg) {
            this.brokerName = res.data.data.BROKERAGETITLE;
            this.contract.brokerFindingContract = false;
            this.contract.brokerContractQuering = true;
            api
              .isContract({ type: "brokerage", id })
              .then((res) => {
                if (res.data.status) {
                  this.contract.brokerFindingContract = true;
                  this.contract.brokerContractData = res.data.data;
                }
              })
              .finally(() => {
                this.contract.senderContractQuering = false;
              });
          }
          else if (res.data.status && res.data.msg) {
            swal(this.$t("dialog.info"), res.data.msg, "info");
          }
          else {
            swal(this.$t("dialog.notice"), this.$t("dialog.anerrorhasccurred") + res.data.errMsg, "error");
          }
        })
        .catch((err) => {
          swal(this.$t("dialog.systemerror"), this.$t("dialog.anerrorhasccurred") + err.toString(), "error");
        });
    },
    senderClearForm() {
      this.contract.senderContractQuering = false;
      this.contract.senderFindingContract = false;
      this.contract.senderContractData = {};
      this.form.PAYTYPEID = null;
    },
    takerClearForm() {
      this.contract.takerContractQuering = false;
      this.contract.takerFindingContract = false;
      this.contract.takerContractData = {};
      this.form.PAYTYPEID = null;
    },
    senderChangePresent(data) {
      //this.form.PAYTYPEID = null;
      this.contract.senderFindingContract = false;
      this.contract.senderContractQuering = true;
      api
        .isContract(data)
        .then((res) => {
          if (res.data.status) {
            this.contract.senderFindingContract = true;
            this.contract.senderContractData = res.data.data;
            //this.isContractTakerPaymentType = this.contract.senderContractData.ISPAYSHIPPINGPRICETAKER;
          }
        })
        .finally(() => {
          this.contract.senderContractQuering = false;
        });
    },
    takerChangePresent(data) {
      this.form.PAYTYPEID = null;
      this.contract.takerFindingContract = false;
      this.contract.takerContractQuering = true;
      api
        .isContract(data)
        .then((res) => {
          if (res.data.status) {
            this.contract.takerFindingContract = true;
            this.contract.takerContractData = res.data.data;
          }
        })
        .finally(() => {
          this.contract.takerContractQuering = false;
        });
    },
    anotherInvoiceIsContract(data) {
      api
        .isContract(data)
        .then((res) => {
          if (res.data.status) {
            this.contract.anotherInvoiceFindingContract = true;
            this.contract.anotherInvoiceContractData = res.data.data;
          }
        });
    },
    getResellerWithSendingAndPayTypeForCargo() {
      api.getResellerWithSendingAndPayTypeForCargo().then((res) => {
        if (res.data.status) {
          this.lookup.sendingTypeData = res.data.data.sendingType;
          this.lookup.originalSendingTypeData = res.data.data.sendingType;
          this.lookup.payTypeData = res.data.data.payType;
          //Öncelike ödeme şekli seçilsin sonra burayı yüklüyoruz
          if (this.onlyShow) {
            this.options.sendingtype = this.lookup.sendingTypeData.map((item) => {
              return {
                text: item.SENDINGTYPENAME,
                value: item.ID,
              };
            });
          }
          this.options.paytype = this.lookup.payTypeData
            .filter((item) => item.ISNONCASH == "0")
            .map((item) => {
              return {
                text: item.PAYTYPENAME,
                value: item.ID.toString(),
              };
            });
          this.options.paytype.unshift({
            text: this.$t("form.selectpaytype"),
            value: null,
            disabled: true,
          });
        }
      });
    },
    checkCouponCode() {
      if (this.coupon.COUPONCODE) {
        api
          .getCampaignCouponWithCouponCode(this.coupon.COUPONCODE)
          .then((res) => {
            // console.log(res);
            if (res.data.status) {
              const coupon = res.data.data;

              // console.log("servisten gelen", coupon.DISCOUNTRATE);
              // console.log("set edilen", parseFloat(coupon.DISCOUNTRATE).toFixed(0));

              this.coupon.isDisabledCoupon = true;
              this.form.COUPONCODE = coupon.COUPONCODE;
              this.form.CAMPAIGNCOUPONID = coupon.ID;
              this.form.ISACCEPTCOUPON = 1;
              this.form.DISCOUNTRATE = parseFloat(coupon.DISCOUNTRATE).toFixed(0);
            }
            else {
              swal(this.$t("dialog.notice"), this.$t("dialog.invalidcouponcode"), "error");
            }
          });
      }
      else {
        swal(this.$t("dialog.notice"), this.$t("dialog.couponcodenotnull"), "error");
      }
    },
    printAction() {
      this.$refs.printerBtn.click();
    },
    getPackages() {
      api.getPackageWithDesiOnlyShow().then((res) => {
        if (res.data.data) {
          this.lookup.packageData = res.data.data;
          this.lookup.originalPackageData = res.data.data;
          this.options.package = res.data.data.map((item) => {
            return {
              text: item.PACKAGENAME,
              value: item.ID,
            };
          });
          this.options.package.unshift({
            text: this.$t("form.selectpackage"),
            value: null,
            disabled: true,
          });
        }
      });
    },
    changeReseller(id) {
      if (this.onlyShow)
        return;
      if (id) {
        this.getResellers(id);
        api.getResellerWithTownId(id, this.form.ISTRANSFER).then((res) => {
          if (res.data.status) {
            this.info.TAKERRESELLERNAME = res.data.data.RESELLERNAME;
            this.form.TAKERRESELLERID = res.data.data.ID;
          }
          else {
            if (res.data.data.notFoundAgent.length) {
              this.dialog.notFoundAgentDialog = true;
              this.dialog.notFoundAgentData = res.data.data.notFoundAgent;
              this.dialog.notFoundAgentOptions =
                res.data.data.notFoundAgent.map((item) => {
                  return {
                    text: item.RESELLERNAME,
                    value: item.ID,
                  };
                });
            }
            else {
              swal(this.$t("dialog.notice"), this.$t("dialog.notfoundagent"), "error");
            }
          }
        });
      }
      else {
        this.info.TAKERRESELLERNAME = null;
        this.form.TAKERRESELLERID = null;
        this.options.resellers = [];
      }
    },
    getSelectedTakerReseller(id) {
      if (id) {
        api.getResellerOne(id).then((res) => {
          if (res.data.status) {
            this.info.TAKERRESELLERNAME = res.data.data.RESELLERNAME;
          }
          else {
            swal(this.$t("dialog.notice"), this.$t("dialog.notfoundagent"), "error");
          }
        });
      }
      else {
        this.info.TAKERRESELLERNAME = null;
      }
    },
    changeResellerSender(id) {
      if (id) {
        api.getResellerWithTownId(id, this.form.ISTRANSFER).then((res) => {
          if (res.data.status) {
            this.info.SENDERRESELLERNAME = res.data.data.RESELLERNAME;
            this.form.SENDERRESELLERID = res.data.data.ID;
          }
          else {
            /**
             * Gonderici icin boyle bir uyari kutusu almak istemiyor yetkililer. cayildi bundan.
             */
            // swal(this.$t("dialog.notice"), this.$t("dialog.notfoundagent"), "error");
          }
        });
      }
      else {
        this.info.SENDERRESELLERNAME = null;
        this.form.SENDERRESELLERID = null;
      }
    },
    changeLine(id) {
      if (id) {
        api.getCityOne(id).then((res) => {
          if (res.data.data) {
            this.form.LINENAME = res.data.data.CITYNAME + " Hattı";
          }
        });
      }
      else {
        this.form.LINENAME = null;
      }
    },
    getCargoType() {
      api.getCargoType().then((res) => {
        if (res.data.status && res.data.msg == null) {
          this.options.cargotype = res.data.data.rows.map((item) => {
            return {
              text: item.CARGOTYPENAME,
              value: item.ID,
            };
          });
          this.options.cargotype.unshift({
            text: this.$t("form.selectcargotype"),
            value: null,
            disabled: true,
          });
        }
      });
    },
    getApiCargoOne(id) {
      api
        .getApiCargoOne(id)
        .then((res) => {
          if (res.data.status && !res.data.msg) {
            const row = res.data.data;
            if (row.ISTRANSFERED == "1") {
              alert(this.$t("dialog.repeatedtransfer") +
                " " +
                row.TRANSFERDATE +
                " " +
                row.TRANSFERTIME);
            }

            // console.log('islem yapılmakta olan', row);

            //this.form.ISBROKERAGES = 1;
            this.form.UNIQVALUE = row.UNIQVALUE;
            //this.form.ISBROKER = row.ISBROKER;
            this.details[0].WIDTH = row.WIDTH;
            this.details[0].HEIGHT = row.HEIGHT;
            this.details[0].LENGTH = row.LENGTH;
            this.details[0].DESI = row.DESI;
            this.details[0].WEIGHT = row.WEIGHT;
            this.$refs.senderPresent.setPresent(row.SENDERID, true, true);
            this.$refs.senderPresent.setPresentAddress(row.SENDERID, row.SENDERADDRESSID);
            //if (this.form.ISBROKER == "1") {
            /*
            this.$refs.senderPresent.setFormData({
              BROKERAGEID: null,
              BROKERAGEAGENTID: null,
              PRESENTID: row.SENDERID,
              ISBROKERAGEPRESENT: 0,
              FIRMNAME: row.SENDERFIRMNAME,
              FULLNAME: row.SENDERFULLNAME,
              IDENTITYNO: row.SENDERIDENTITYNO,
              TAXOFFICE: row.SENDERTAXOFFICE,
              PHONE: row.SENDERPHONE,
              GSM: row.SENDERGSM,
              ADDRESSID: row.SENDERADDRESSID,
              COUNTRYID: null,
              REGIONID: null,
              CITYID: null,
              TOWNID: null,
              COUNTYID: null,
              ADDRESS: null,
            });
            console.log({
              BROKERAGEID: null,
              BROKERAGEAGENTID: null,
              PRESENTID: row.SENDERID,
              ISBROKERAGEPRESENT: 0,
              FIRMNAME: row.SENDERFIRMNAME,
              FULLNAME: row.SENDERFULLNAME,
              IDENTITYNO: row.SENDERIDENTITYNO,
              TAXOFFICE: row.SENDERTAXOFFICE,
              PHONE: row.SENDERPHONE,
              GSM: row.SENDERGSM,
              ADDRESSID: row.SENDERADDRESSID,
              COUNTRYID: null,
              REGIONID: null,
              CITYID: null,
              TOWNID: null,
              COUNTYID: null,
              ADDRESS: null,
            });*/
            //return;
            //this.getBrokerageOne(row.BROKERAGEID);
            //} else {
            //this.isBrokerage = true;
            /*
              this.$refs.senderPresent.setFormBrokerAgeData(
                1,
                row.BROKERAGEID,
                row.BROKERAGEAGENTID
              );*/
            //}
            this.$refs.takerPresent.setFormData({
              BROKERAGEID: null,
              BROKERAGEAGENTID: null,
              PRESENTID: null,
              ISBROKERAGEPRESENT: 0,
              FIRMNAME: row.TAKERFIRMNAME,
              FULLNAME: row.TAKERFULLNAME,
              IDENTITYNO: row.TAKERIDENTITYNO,
              TAXOFFICE: row.TAKERTAXOFFICE,
              PHONE: row.TAKERPHONE,
              GSM: row.TAKERGSM,
              ADDRESSID: null,
              COUNTRYID: null,
              REGIONID: null,
              CITYID: null,
              TOWNID: null,
              COUNTYID: null,
              ADDRESS: row.TAKERADDRESS +
                "\n" +
                (row.TAKERTOWN ? row.TAKERTOWN : "") +
                " " +
                row.TAKERCOUNTY +
                "/" +
                row.TAKERCITY,
              FIRSTNAME: null,
              SECONDNAME: null,
              LASTNAME: null,
            });
            this.$refs.takerPresent.setAddressSync(row.TAKERCITY, row.TAKERCOUNTY, row.TAKERADDRESS);
            this.form.INTEGRATIONBARCODE = row.INTEGRATIONBARCODE;
            this.form.TRACKINGNO = row.TRACKINGNO;
            /**
 0 -->Kapıda Ödeme Nakit
1 --> Kapıda Ödeme Kredi Kartı
2 --> Ödeme Alındı şeklindedir.
 
 
0 ve 1 kapıda tahsilatlı kargo, 2 ise tahsilatsız şeklindedir.
 */
            //only huncalife
            this.$nextTick(() => {
              if (row.PAYMENTTYPE == "0") {
                const findedSendingTypePaymentDoor = this.lookup.sendingTypeData.find((item) => item.ISPAYMENTDOOR.toString() == "1");
                const findedPayTypeBuyerPaid = this.lookup.payTypeData.find((item) => item.ISBBUYERPAID.toString() == "1");
                if (findedPayTypeBuyerPaid) {
                  this.form.PAYTYPEID = findedPayTypeBuyerPaid.ID.toString();
                }
                if (findedSendingTypePaymentDoor) {
                  this.form.SENDINGTYPEID = findedSendingTypePaymentDoor.ID;
                }
                this.form.DESCRIPTION = "Kapıda Ödeme Nakit";
              }
              else if (row.PAYMENTTYPE == "1") {
                this.form.DESCRIPTION = "Kapıda Ödeme Kredi Kartı";
              }
              else if (row.PAYMENTTYPE == "2") {
                this.form.PRODUCTPRICE = 0;
                const findedNonCash = this.lookup.payTypeData.find((item) => item.ISCASH.toString() == "1");
                const findedSendingTypeStandart = this.lookup.sendingTypeData.find((item) => item.ISPAYMENTDOOR.toString() == "0");
                if (findedNonCash) {
                  this.form.PAYTYPEID = findedNonCash.ID;
                }
                if (findedSendingTypeStandart) {

                  this.form.SENDINGTYPEID = findedSendingTypeStandart.ID;
                }
                this.form.DESCRIPTION = "Ödeme Alındı";
              }
            });
            this.form.PRODUCTPRICE = toTrFromNumber(parseFloat(row.TOTALAMOUNT) || 0);

            this.details[0].PIECE = 1;
            this.details[0].PACKAGEID = 5;
            this.details[0].CARGOTYPEID = 1;
          }
          else if (res.data.status && res.data.msg) {
            alert(res.data.msg);
          }
          else {
            swal(this.$t("dialog.notice"), this.$t("dialog.anerrorhasccurred") + res.data.errMsg, "error");
          }
        })
        .catch((err) => {
          swal(this.$t("dialog.systemerror"), this.$t("dialog.anerrorhasccurred") + err.toString(), "error");
        });
    },
    printInvoiceAction() {
      this.$refs.printerInvoiceBtn.click();
    },
    save() {
      this.buttonLoading = true;
      if (intval(this.ID) > 0) {
        swal(this.$t("dialog.notice"), this.$t("dialog.noauthforedit"), "warning");
        this.buttonLoading = false;
        return;
      }
      this.form.ISTAKERCONTRACT = this.contract.takerFindingContract ? 1 : 0;
      this.form.ISSENDERCONTRACT = this.contract.senderFindingContract ? 1 : 0;
      this.form.ISBROKERCONTRACT = this.contract.brokerFindingContract ? 1 : 0;
      this.form.ISANOTHERINVOICECONTRACT = this.contract.anotherInvoiceFindingContract ? 1 : 0;
      let senderPresent;
      let takerPresent;
      this.$refs.senderPresent
        .getFormPresent()
        .then((sPresent) => {
          senderPresent = sPresent;
          return this.$refs.takerPresent.getFormPresent();
        })
        .then((tPresent) => {
          takerPresent = tPresent;
          return Promise.resolve();
        })
        .then(() => {
          if (this.details.length < 0) {
            swal(this.$t("dialog.notice"), "En az 1 adet kargo bilgisi girmelisiniz!", "warning");
            this.buttonLoading = false;
            return;
          }

          if (this.details.filter(item => item.PACKAGEID == null).length) {
            swal(this.$t("dialog.notice"), "Kargo detayında paket bilgisi eksik!", "warning");
            this.buttonLoading = false;
            return;
          }
          if (this.details.filter(item => item.CARGOTYPEID == null).length) {
            swal(this.$t("dialog.notice"), "Kargo detayında kargo tipi bilgisi eksik!", "warning");
            this.buttonLoading = false;
            return;
          }
          if (this.details.filter(item => (parseInt(item.PIECE) || 0) == 0).length) {
            swal(this.$t("dialog.notice"), "Kargo detayında adet bilgisi geçersiz!", "warning");
            this.buttonLoading = false;
            return;
          }

          if (this.details.filter(item => item.ISREQUIREDDESI && (parseFloat(item.DESI) || 0) == 0 && (parseFloat(item.WEIGHT) || 0) == 0).length) {
            swal(this.$t("dialog.notice"), "Kargo detayında desi veya ağırlık 0dan büyük olmalı!", "warning");
            this.buttonLoading = false;
            return;
          }

          /*
          if (!this.form.PACKAGEID) {
            swal(this.$t("dialog.notice"), this.$t("validate.packagenotnull"), "error");
            this.buttonLoading = false;
            return;
          }
          if (!this.form.PIECE) {
            swal(this.$t("dialog.notice"), this.$t("validate.cargopiecenotnull"), "error");
            this.buttonLoading = false;
            return;
          }
          if (this.form.DESI == null) {
            swal(this.$t("dialog.notice"), this.$t("validate.decinotnull"), "error");
            this.buttonLoading = false;
            return;
          }
          if (this.form.WEIGHT == null) {
            swal(this.$t("dialog.notice"), this.$t("validate.weightnotnull"), "error");
            this.buttonLoading = false;
            return;
          }
          */
          // if(!this.form.SENDERRESLLERID){
          //   swal(this.$t("dialog.notice"), "Gönderici şubeyle eşleşen mahalle olmadığı için gönderim yapamazsınız!", "error");
          //   return;
          // }
          if (!this.form.PAYTYPEID) {
            swal(this.$t("dialog.notice"), this.$t("validate.paytypenotnull"), "error");
            this.buttonLoading = false;
            return;
          }
          if (!this.form.SENDINGTYPEID) {
            swal(this.$t("dialog.notice"), this.$t("validate.sendingtypenotnull"), "error");
            this.buttonLoading = false;
            return;
          }
          /*
          if (!this.form.CARGOTYPEID) {
            swal(this.$t("dialog.notice"), this.$t("validate.cargotypenotnull"), "error");
            this.buttonLoading = false;
            return;
          }*/
          const findedSendingTypePaymentDoor = this.lookup.sendingTypeData.find((item) => item.ID.toString() == this.form.SENDINGTYPEID.toString() &&
            item.ISPAYMENTDOOR.toString() == "1");
          if (findedSendingTypePaymentDoor) {
            const findedPayTypeBuyerPaid = this.lookup.payTypeData.find((item) => item.ID.toString() == this.form.PAYTYPEID.toString() &&
              item.ISBBUYERPAID.toString() == "1");
            if (!findedPayTypeBuyerPaid) {
              swal(this.$t("dialog.notice"), this.$t("validate.paymentindoorerror"), "error");
              this.buttonLoading = false;
              return;
            }
          }
          if (this.form.DELIVERYTYPE == "1") {
            if (!this.form.TAKERRESELLERID) {
              swal(this.$t("dialog.notice"), this.$t("validate.takeragentnotfound"), "error");
              this.buttonLoading = false;
              return;
            }
          }
          else {
            if (!this.form.DELIVERYRESELLERID) {
              swal(this.$t("dialog.notice"), this.$t("validate.takerdeliverynotfound"), "error");
              this.buttonLoading = false;
              return;
            }
            this.form.TAKERRESELLERID = this.form.DELIVERYRESELLERID;
          }
          if (this.anotherInvoice) {
            if (this.form.USEANOTHERINVOICE) {
              if (this.selected.anotherInvoice == null) {
                swal(this.$t("dialog.notice"), this.$t("validate.anotherinvoicenotselected"), "error");
                this.buttonLoading = false;
                return;
              }
              else {
                this.form.ANOTHERINVOICEPRESENTID = this.selected.anotherInvoice.PRESENTID;
                this.form.ANOTHERINVOICEPRESENTADDRESSID = this.selected.anotherInvoice.ADDRESSID;
              }
              if (!this.form.ANOTHERINVOICEPRESENTID) {
                swal(this.$t("dialog.notice"), this.$t("validate.anotherinvoicenotnull"), "error");
                this.buttonLoading = false;
                return;
              }
            }
          }
          else {
            this.form.ANOTHERINVOICEPRESENTID = null;
            this.form.ANOTHERINVOICEPRESENTADDRESSID = null;
          }
          if (this.form.USEWAYBILL) {
            if (this.form.WAYBILLNO == null) {
              swal(this.$t("dialog.notice"), this.$t("validate.waybillnotnull"), "error");
              this.buttonLoading = false;
              return;
            }
          }
          else {
            this.form.WAYBILLNO = null;
          }
          if (senderPresent.ISBROKERAGEPRESENT) {
            this.form.ISBROKERAGES = 1;
            this.form.BROKERAGEID = senderPresent.BROKERAGEID;
            this.form.BROKERAGEAGENTID = senderPresent.BROKERAGEAGENTID;
          }
          if (senderPresent.PRESENTID) {
            this.form.SENDERID = senderPresent.PRESENTID;
          }
          else {
            this.form.SENDERPRESENT = senderPresent;
          }
          if (senderPresent.ADDRESSID) {
            this.form.SENDERADDRESSID = senderPresent.ADDRESSID;
          }
          else {
            this.form.SENDERPRESENTADDRESS = senderPresent;
          }
          if (takerPresent.PRESENTID) {
            this.form.TAKERID = takerPresent.PRESENTID;
          }
          else {
            this.form.TAKERPRESENT = takerPresent;
          }
          if (takerPresent.ADDRESSID) {
            this.form.TAKERADDRESSID = takerPresent.ADDRESSID;
          }
          else {
            this.form.TAKERPRESENTADDRESS = takerPresent;
          }

          this.loadingMsg = this.$t("dialog.fetchingdata");
          this.loadingShow = true;
          api
            .saveCargo({
              ...this.form,
              // PRODUCTPRICE: toNumberFromTr(this.form.PRODUCTPRICE),
              PRODUCTPRICE: this.form.PRODUCTPRICE,
              totals: this.totals,
              details: this.details,
            }, this.ID)
            .then((res) => {
              if (res.data.status) {
                if (this.ID == res.data.data.ID) {
                  swal(this.$t("dialog.success"), this.$t("dialog.successfullyupdatedrecord"), "success");
                }
                else {
                  this.ID = res.data.data.ID;
                  swal({
                    icon: "success",
                    title: this.$t("dialog.success"),
                    text: this.$t("dialog.isprintbarcode"),
                    buttons: [this.$t("dialog.cancel"), this.$t("dialog.yes")],
                  })
                    .then((isOk) => {
                      if (isOk) {
                        this.printAction();
                      }
                    })
                    .finally(() => {
                      swal({
                        icon: "success",
                        title: this.$t("dialog.success"),
                        text: this.$t("dialog.isprintinvoice"),
                        buttons: [
                          this.$t("dialog.cancel"),
                          this.$t("dialog.yes"),
                        ],
                      }).then((isOkInvoice) => {
                        if (isOkInvoice) {
                          this.printInvoiceAction();
                        }
                      });
                    });
                }
              }
              else {
                swal(this.$t("dialog.notice"), this.$t("dialog.anerrorhasccurred") + res.data.errMsg, "error");
              }
              this.buttonLoading = false;
            })
            .catch((err) => {
              swal(this.$t("dialog.systemerror"), this.$t("dialog.anerrorhasccurred") + err.toString(), "error");
              this.buttonLoading = false;
            })
            .finally(() => {
              this.loadingShow = false;
            });
        })
        .catch((err) => {
          this.buttonLoading = false;
        })
        .finally(() => { });
    },
    getCargo(id) {
      this.loadingMsg = this.$t("dialog.fetchingdata");
      this.loadingShow = true;
      api
        .getCargoOne(id)
        .then((res) => {
          if (res.data.status && !res.data.msg) {
            const { BOXID, ISBROKERAGES, BROKERAGEID, BROKERAGEAGENTID, PACKAGEID, SENDERID, TAKERID, SENDERADDRESSID, TAKERADDRESSID, SENDERRESELLERID, TAKERRESELLERID, LINENAME, DESI, WEIGHT, PIECE, PRICE, GROSSAMOUNT, DISCOUNTRATE, DISCOUNTAMOUNT, DISTANCEAMOUNT, TAXRATE, TAXAMOUNT, SUBTOTAL, NETAMOUNT, BROKERAGEDATE, BROKERAGETIME, SAVEDATE, SAVETIME, INTEGRATIONBARCODE, BARCODE, DESCRIPTION, LASTSTATUS, LASTSTATUSDATE, LASTSTATUSTIME, ISDELIVERY, DELIVERYUSERID, DELIVERYDATE, DELIVERYTIME, DELIVEREDFULLNAME, DELIVEREDIDENTITYNO, ISACCEPTCOUPON, COUPONCODE, CAMPAIGNCOUPONID, ISRETURN, ISINFORMATIONFORM, SENDINGTYPEID, PAYTYPEID, PRODUCTPRICE, PAYMENTDOORPRICE, TOTALAMOUNT, CARGOTYPEID, ISTAKERCONTRACT, ISSENDERCONTRACT, ISBROKERCONTRACT, UNIQVALUE, ISBROKER, ISCANCEL, DELIVERYTYPE, DELIVERYRESELLERID, ANOTHERINVOICEPRESENTID, ANOTHERINVOICEPRESENTADDRESSID, USEANOTHERINVOICE, PREDEFINEDVEHICLEID, WAYBILLNO, USEWAYBILL, } = res.data.data.cargo;
            this.form = {
              BOXID,
              ISBROKERAGES,
              BROKERAGEID,
              BROKERAGEAGENTID,
              PACKAGEID,
              SENDERID,
              TAKERID,
              SENDERADDRESSID,
              TAKERADDRESSID,
              SENDERRESELLERID,
              TAKERRESELLERID,
              LINENAME,
              DESI,
              PIECE,
              PRICE,
              GROSSAMOUNT,
              DISCOUNTRATE,
              DISCOUNTAMOUNT,
              DISTANCEAMOUNT,
              TAXRATE,
              TAXAMOUNT,
              SUBTOTAL,
              NETAMOUNT,
              BROKERAGEDATE,
              BROKERAGETIME,
              SAVEDATE,
              SAVETIME,
              INTEGRATIONBARCODE,
              BARCODE,
              DESCRIPTION,
              LASTSTATUS,
              LASTSTATUSDATE,
              LASTSTATUSTIME,
              ISDELIVERY,
              DELIVERYUSERID,
              DELIVERYDATE,
              DELIVERYTIME,
              DELIVEREDFULLNAME,
              DELIVEREDIDENTITYNO,
              ISACCEPTCOUPON,
              COUPONCODE,
              CAMPAIGNCOUPONID,
              ISRETURN,
              ISINFORMATIONFORM,
              SENDINGTYPEID,
              PAYTYPEID,
              PRODUCTPRICE: toTrFromNumber(PRODUCTPRICE),
              PAYMENTDOORPRICE,
              TOTALAMOUNT,
              CARGOTYPEID,
              ISTAKERCONTRACT,
              ISSENDERCONTRACT,
              ISBROKERCONTRACT,
              UNIQVALUE,
              ISBROKER,
              ISCANCEL,
              WEIGHT,
              DELIVERYTYPE,
              DELIVERYRESELLERID,
              ANOTHERINVOICEPRESENTID,
              ANOTHERINVOICEPRESENTADDRESSID,
              USEANOTHERINVOICE: USEANOTHERINVOICE == "0" ? false : true,
              WAYBILLNO,
              USEWAYBILL: USEWAYBILL == "0" ? false : true,
              PREDEFINEDVEHICLEID,
            };
            this.$refs.senderPresent.setPresent(SENDERID);
            this.$refs.senderPresent.setPresentAddress(SENDERID, SENDERADDRESSID);
            this.$refs.takerPresent.setPresent(TAKERID);
            this.$refs.takerPresent.setPresentAddress(TAKERID, TAKERADDRESSID);
            if (res.data.data.staticPrices) {
              this.form.STATICPRICES = res.data.data.staticPrices.map((item) => item.STATICPRICEID);
            }
            if (res.data.data.totals) {
              this.totals = res.data.data.totals;
            }
            this.getSelectedTakerReseller(this.form.TAKERRESELLERID);


            if (res.data.data.details) {
              this.details = res.data.data.details.map(item => {
                return {


                  ID: item.ID,
                  PIECE: item.GROUPTOTALPIECE,
                  PACKAGEID: item.PACKAGEID,
                  CARGOTYPEID: item.CARGOTYPEID,
                  BOXID: item.BOXID,
                  DESI: item.DESI,
                  WEIGHT: item.WEIGHT,

                  WIDTH: item.WIDTH,
                  LENGTH: item.LENGTH,
                  HEIGHT: item.HEIGHT,

                  ISREQUIREDDESI: !!item.ISREQUIREDDESI,

                  BOXPRICE: item.BOXPRICE,
                  BOXGROSSAMOUNT: item.BOXGROSSAMOUNT,
                  BOXTAXRATE: item.BOXTAXRATE,
                  BOXTAXAMOUNT: item.BOXTAXAMOUNT,
                  BOXNETAMOUNT: item.BOXNETAMOUNT,

                  PACKAGEPRICE: item.PACKAGEPRICE,
                  PACKAGEGROSSAMOUNT: item.PACKAGEGROSSAMOUNT,
                  PACKAGETAXRATE: item.PACKAGETAXRATE,
                  PACKAGETAXAMOUNT: item.PACKAGETAXAMOUNT,
                  PACKAGENETAMOUNT: item.PACKAGENETAMOUNT,

                  GROSSAMOUNT: item.GROSSAMOUNT,
                  TAXAMOUNT: item.TAXAMOUNT,
                  NETAMOUNT: item.NETAMOUNT,
                };
              });
            }
          }
          else if (res.data.status && res.data.msg) {
            swal(this.$t("dialog.info"), res.data.msg, "info");
          }
          else {
            swal(this.$t("dialog.notice"), this.$t("dialog.anerrorhasccurred") + res.data.errMsg, "error");
          }
        })
        .catch((err) => {
          swal(this.$t("dialog.systemerror"), this.$t("dialog.anerrorhasccurred") + err.toString(), "error");
        })
        .finally(() => {
          this.loadingShow = false;
        });
    },
    remove() {
      swal(this.$t("dialog.notice"), this.$t("dialog.noauthforremove"), "warning");
      return;
      this.loadingMsg = this.$t("dialog.removingrecord");
      this.loadingShow = true;
      api
        .deleteCargo(this.ID)
        .then((res) => {
          if (res.data.status && res.data.msg) {
            swal(this.$t("dialog.info"), res.data.msg, "info");
          }
          else {
            swal(this.$t("dialog.notice"), this.$t("dialog.anerrorhasccurred") + res.data.errMsg, "error");
          }
        })
        .catch((err) => {
          swal(this.$t("dialog.systemerror"), this.$t("dialog.anerrorhasccurred") + err.toString(), "error");
        })
        .finally(() => {
          this.loadingShow = false;
        });
    },
    calcPrice() {
      this.resetPrice();

      this.form.PIECE = this.details.reduce((total, item) => parseFloat(total) + (parseFloat(item.PIECE) || 0), 0);
      this.form.DESI = this.details.reduce((total, item) => parseFloat(total) + (parseFloat(item.DESI) || 0), 0);
      this.form.WEIGHT = this.details.reduce((total, item) => parseFloat(total) + (parseFloat(item.WEIGHT) || 0), 0);
      this.totals.TAXRATE = this.form.TAXRATE = 20;

      const findedBuyerPaid = this.lookup.payTypeData.find((item) => {
        return (item.ID == this.form.PAYTYPEID &&
          item.ISBBUYERPAID.toString() == "1");
      });
      /***********************************yeni ücretler****************************/
      this.totals.PACKPRICE = this.details.reduce((total, item) => parseFloat(total) + (parseFloat(item.PACKAGEGROSSAMOUNT) || 0), 0);

      this.totals.PAYMENTDOORPRICE = 0;

      if ((parseFloat(this.lookup.sendingTypePrice) || 0) > 0) {
        this.totals.PAYMENTDOORPRICE = parseFloat(this.lookup.sendingTypePrice);
      }

      this.totals.BOXPRICE = this.details.reduce((total, item) => parseFloat(total) + (parseFloat(item.BOXGROSSAMOUNT) || 0), 0);

      this.totals.ADDITIONALPAYPRICE = 0;
      if (this.form.STATICPRICES.length) {
        this.totals.ADDITIONALPAYPRICE = this.lookup.staticPrice
          .filter((item) => this.form.STATICPRICES.includes(item.ID))
          .reduce((amount, item) => {
            const price = parseFloat(item.PRICE);
            return parseFloat(amount) + price;
          }, 0);
      }
      this.totals.INSURANCERATE = 0;
      this.totals.INSURANCEPRICE = 0;
      this.totals.ADDITIONALSERVICEPRICE =
        this.totals.PAYMENTDOORPRICE +
        this.totals.BOXPRICE +
        this.totals.ADDITIONALPAYPRICE +
        this.totals.INSURANCEPRICE;
      this.totals.GROSSPRICE =
        (this.totals.PACKPRICE) + this.totals.ADDITIONALSERVICEPRICE;

      this.totals.DISCOUNTRATE = parseFloat(this.form.DISCOUNTRATE) || 0;

      if (this.totals.DISCOUNTRATE > 0) {
        if (this.contract.anotherInvoiceFindingContract && this.form.USEANOTHERINVOICE) {
          this.isUseDiscountAll =
            this.contract.anotherInvoiceContractData.ISUSEDISCOUNTALL == "1"
              ? true
              : false;
        }
        if (this.contract.senderFindingContract && !findedBuyerPaid && !this.form.USEANOTHERINVOICE) {
          this.isUseDiscountAll =
            this.contract.senderContractData.ISUSEDISCOUNTALL == "1"
              ? true
              : false;
        }
        if (this.contract.takerFindingContract && findedBuyerPaid && !this.form.USEANOTHERINVOICE) {
          this.isUseDiscountAll =
            this.contract.takerContractData.ISUSEDISCOUNTALL == "1"
              ? true
              : false;
        }
        if (this.isUseDiscountAll) {
          this.totals.DISCOUNTAMOUNT =
            (this.totals.GROSSPRICE * this.totals.DISCOUNTRATE) / 100;
        }
        else {
          this.totals.DISCOUNTAMOUNT =
            (this.totals.PACKPRICE * this.totals.DISCOUNTRATE) / 100;
          this.totals.DISCOUNTRATE =
            (this.totals.DISCOUNTAMOUNT / this.totals.GROSSPRICE) * 100;
        }
      }
      this.totals.DISTANCEAMOUT =
        this.totals.GROSSPRICE - this.totals.DISCOUNTAMOUNT;
      this.totals.POSTASERVICECOSTRATE = parseFloat(this.postaServiceCostRate);
      this.totals.POSTASERVICECOST = parseFloat((this.totals.DISTANCEAMOUT * this.totals.POSTASERVICECOSTRATE) /
        100 || 0).toFixed(2);
      this.totals.SUBAMOUNT = parseFloat((this.totals.DISTANCEAMOUT +
        (parseFloat(this.totals.POSTASERVICECOST) || 0)).toFixed(2));
      this.totals.TAXAMOUNT = parseFloat(((this.totals.SUBAMOUNT * this.totals.TAXRATE) / 100).toFixed(2));
      this.totals.NETAMOUNT = parseFloat((this.totals.SUBAMOUNT + this.totals.TAXAMOUNT).toFixed(2));
      this.totals.PRODUCTPRICE = this.form.PRODUCTPRICE;
      if (this.contract.anotherInvoiceFindingContract) {
        if (this.contract.anotherInvoiceContractData.ISUSEPAYTYPE == "1") {
          if (findedBuyerPaid) {
            this.totals.TAKERAMOUNT = this.totals.NETAMOUNT;
          }
        }
        else {
          if (this.contract.anotherInvoiceContractData.ISPAYPAYMENTDOORTAKER == "1") {
            this.totals.TAKERAMOUNT +=
              parseFloat(this.totals.PAYMENTDOORPRICE) || 0;
          }
          if (
            //Enexpressin üzerine sürekli değişken oalbileceği bildirildi.
            this.contract.anotherInvoiceContractData.ISPAYSHIPPINGPRICETAKER == "1"
            //Enexpressin talebi üzerine tekrar geri alındı
            //this.isContractTakerPaymentType == "1"
          ) {
            this.totals.TAKERAMOUNT += parseFloat(this.totals.PACKPRICE) || 0;
          }
          if (this.contract.anotherInvoiceContractData.ISPAYBOXAMOUNTTAKER == "1") {
            this.totals.TAKERAMOUNT += parseFloat(this.totals.BOXPRICE) || 0;
          }
          if (this.contract.anotherInvoiceContractData.ISPAYSTATICPRICEAMOUNTTAKER ==
            "1") {
            this.totals.TAKERAMOUNT +=
              parseFloat(this.totals.ADDITIONALPAYPRICE) || 0;
          }
          if (this.contract.anotherInvoiceContractData.ISPAYINSURANCEPAYTAKER == "1") {
            this.totals.TAKERAMOUNT +=
              parseFloat(this.totals.INSURANCEPRICE) || 0;
          }
          //indirim posta hizmet bedilinden önce uygulandığı için bu şekilde yapıyoruz.
          if (this.contract.anotherInvoiceContractData.ISPAYPOSTASERVICECOSTTAKER == "1") {
            this.totals.TAKERAMOUNT -= parseFloat(((this.totals.TAKERAMOUNT * this.totals.DISCOUNTRATE) /
              100).toFixed(2));
            this.totals.TAKERAMOUNT +=
              parseFloat(this.totals.POSTASERVICECOST) || 0;
          }
          else {
            this.totals.TAKERAMOUNT -= parseFloat(((this.totals.TAKERAMOUNT * this.totals.DISCOUNTRATE) /
              100).toFixed(2));
          }
          this.totals.TAKERAMOUNT +=
            (this.totals.TAKERAMOUNT * this.totals.TAXRATE) / 100;
        }
      }
      else if (this.contract.senderFindingContract) {
        if (this.contract.senderContractData.ISUSEPAYTYPE == "1") {
          if (findedBuyerPaid) {
            this.totals.TAKERAMOUNT = this.totals.NETAMOUNT;
          }
        }
        else {
          if (this.contract.senderContractData.ISPAYPAYMENTDOORTAKER == "1") {
            this.totals.TAKERAMOUNT +=
              parseFloat(this.totals.PAYMENTDOORPRICE) || 0;
          }
          if (
            //Enexpressin üzerine sürekli değişken oalbileceği bildirildi.
            this.contract.senderContractData.ISPAYSHIPPINGPRICETAKER == "1"
            //Enexpressin talebi üzerine tekrar geri alındı
            //this.isContractTakerPaymentType == "1"
          ) {
            this.totals.TAKERAMOUNT += parseFloat(this.totals.PACKPRICE) || 0;
          }
          if (this.contract.senderContractData.ISPAYBOXAMOUNTTAKER == "1") {
            this.totals.TAKERAMOUNT += parseFloat(this.totals.BOXPRICE) || 0;
          }
          if (this.contract.senderContractData.ISPAYSTATICPRICEAMOUNTTAKER ==
            "1") {
            this.totals.TAKERAMOUNT +=
              parseFloat(this.totals.ADDITIONALPAYPRICE) || 0;
          }
          if (this.contract.senderContractData.ISPAYINSURANCEPAYTAKER == "1") {
            this.totals.TAKERAMOUNT +=
              parseFloat(this.totals.INSURANCEPRICE) || 0;
          }
          //indirim posta hizmet bedilinden önce uygulandığı için bu şekilde yapıyoruz.
          if (this.contract.senderContractData.ISPAYPOSTASERVICECOSTTAKER == "1") {
            this.totals.TAKERAMOUNT -= parseFloat(((this.totals.TAKERAMOUNT * this.totals.DISCOUNTRATE) /
              100).toFixed(2));
            this.totals.TAKERAMOUNT +=
              parseFloat(this.totals.POSTASERVICECOST) || 0;
          }
          else {
            this.totals.TAKERAMOUNT -= parseFloat(((this.totals.TAKERAMOUNT * this.totals.DISCOUNTRATE) /
              100).toFixed(2));
          }
          this.totals.TAKERAMOUNT +=
            (this.totals.TAKERAMOUNT * this.totals.TAXRATE) / 100;
        }
      }
      else if (this.contract.takerFindingContract && findedBuyerPaid) {
        if (this.contract.takerContractData.ISUSEPAYTYPE == "1") {
          if (findedBuyerPaid) {
            this.totals.TAKERAMOUNT = this.totals.NETAMOUNT;
          }
        }
        else {
          if (this.contract.takerContractData.ISPAYPAYMENTDOORTAKER == "1") {
            this.totals.TAKERAMOUNT +=
              parseFloat(this.totals.PAYMENTDOORPRICE) || 0;
          }
          if (this.contract.takerContractData.ISPAYSHIPPINGPRICETAKER == "1") {
            this.totals.TAKERAMOUNT += parseFloat(this.totals.PACKPRICE) || 0;
          }
          if (this.contract.takerContractData.ISPAYBOXAMOUNTTAKER == "1") {
            this.totals.TAKERAMOUNT += parseFloat(this.totals.BOXPRICE) || 0;
          }
          if (this.contract.takerContractData.ISPAYSTATICPRICEAMOUNTTAKER == "1") {
            this.totals.TAKERAMOUNT +=
              parseFloat(this.totals.ADDITIONALPAYPRICE) || 0;
          }
          if (this.contract.takerContractData.ISPAYINSURANCEPAYTAKER == "1") {
            this.totals.TAKERAMOUNT +=
              parseFloat(this.totals.INSURANCEPRICE) || 0;
          }
          //indirim posta hizmet bedilinden önce uygulandığı için bu şekilde yapıyoruz.
          if (this.contract.takerContractData.ISPAYPOSTASERVICECOSTTAKER == "1") {
            this.totals.TAKERAMOUNT -= parseFloat(((this.totals.TAKERAMOUNT * this.totals.DISCOUNTRATE) /
              100).toFixed(2));
            this.totals.TAKERAMOUNT +=
              parseFloat(this.totals.POSTASERVICECOST) || 0;
          }
          else {
            this.totals.TAKERAMOUNT -= parseFloat(((this.totals.TAKERAMOUNT * this.totals.DISCOUNTRATE) /
              100).toFixed(2));
          }
          this.totals.TAKERAMOUNT +=
            (this.totals.TAKERAMOUNT * this.totals.TAXRATE) / 100;
        }
      }
      else if (findedBuyerPaid) {
        this.totals.TAKERAMOUNT = parseFloat(this.totals.NETAMOUNT.toFixed(2));
      }
      this.totals.SENDERAMOUNT = (this.totals.NETAMOUNT - this.totals.TAKERAMOUNT).toFixed(2);
      // this.totals.TAKERAMOUNT += parseFloat(toNumberFromTr(this.totals.PRODUCTPRICE).toFixed(2));
      this.totals.TAKERAMOUNT += parseFloat(this.totals.PRODUCTPRICE.toFixed(2));

      // console.log("totals > TAKERAMOUNT : ", this.totals.TAKERAMOUNT);
      //  this.totals.TAKERAMOUNT = this.totals.TAKERAMOUNT.toFixed(2);
      this.form.PRICE = parseFloat(this.totals.PACKPRICE).toFixed(2);
      this.form.STATICPRICEAMOUNT = this.totals.ADDITIONALPAYPRICE;
      this.form.BOXAMOUNT = this.totals.BOXPRICE;
      this.form.INSURANCEPAY = this.totals.INSURANCEPRICE;
      this.form.TOTALAMOUNT = this.totals.TAKERAMOUNT;
      this.form.NETAMOUNT = this.totals.SENDERAMOUNT;
      this.form.GROSSAMOUNT = this.totals.GROSSPRICE; //paket ücreti
      this.form.DISCOUNTAMOUNT = this.totals.DISCOUNTAMOUNT; //indirim tutarı
      this.form.DISTANCEAMOUNT = this.totals.DISTANCEAMOUT; //indirimi düşülmüş paket ücreti
      this.form.POSTASERVICECOST = this.totals.POSTASERVICECOST;
      this.form.SUBTOTAL = this.totals.SUBAMOUNT; //
      this.form.TAXAMOUNT = this.totals.TAXAMOUNT; // paket kdv tutarı

    },
    resetPrice() {
      this.totals.PACKPRICE = 0;
      this.totals.TAXRATE = 0;
      this.totals.PAYMENTDOORPRICE = 0;
      this.totals.BOXPRICE = 0;
      this.totals.ADDITIONALPAYPRICE = 0;
      this.totals.INSURANCERATE = 0;
      this.totals.INSURANCEPRICE = 0;
      this.totals.ADDITIONALSERVICEPRICE = 0;
      this.totals.GROSSPRICE = 0;
      this.totals.DISCOUNTRATE = 0;
      this.totals.DISCOUNTAMOUNT = 0;
      this.totals.DISTANCEAMOUT = 0;
      this.totals.POSTASERVICECOST = 0;
      this.totals.SUBAMOUNT = 0;
      this.totals.TAXAMOUNT = 0;
      this.totals.NETAMOUNT = 0;
      this.totals.PRODUCTPRICE = 0;
      this.totals.SENDERAMOUNT = 0;
      this.totals.TAKERAMOUNT = 0;
      this.form.PRICE = 0;
      this.form.STATICPRICEAMOUNT = this.totals.ADDITIONALPAYPRICE;
      this.form.BOXAMOUNT = this.totals.BOXPRICE;
      this.form.INSURANCEPAY = this.totals.INSURANCEPRICE;
      this.form.TOTALAMOUNT = this.totals.TAKERAMOUNT;
      this.form.NETAMOUNT = this.totals.SENDERAMOUNT;
      this.form.GROSSAMOUNT = this.totals.GROSSPRICE; //paket ücreti
      // this.form.DISCOUNTRATE = this.totals.DISCOUNTRATE; //indirim oranı
      // this.form.DISCOUNTAMOUNT = this.totals.DISCOUNTAMOUNT; //indirim tutarı
      this.form.DISTANCEAMOUNT = this.totals.DISTANCEAMOUT; //indirimi düşülmüş paket ücreti
      this.form.POSTASERVICECOST = this.totals.POSTASERVICECOST;
      this.form.SUBTOTAL = this.totals.SUBAMOUNT; //
      this.form.TAXAMOUNT = this.totals.TAXAMOUNT; // paket kdv tutarı
    },
  },
  watch: {

    /**
     * 4Mart2020 piece ile çarpım kaldırıldı.
     *
     */
    /**
          * ? Dolayısıyla  piecenin değişimini takip etmeye gerek kalmadı :)
          */
    /**
     * ? 16Haziran2022 piece ile çarpım yeniden eklendi
     */

    "form.PRODUCTPRICE"() {
      if (this.onlyShow)
        return;
      this.calcPrice();
    },
    "form.PAYMENTDOORPRICE"() {
      if (this.onlyShow)
        return;
      this.calcPrice();
    },
    "form.DISCOUNTRATE"(val, oldVal) {
      if (this.onlyShow)
        return;
      if (parseFloat(val) != parseFloat(oldVal)) {
        this.calcPrice();
      }
    },

    "form.COUPONCODE"(val) {
      if (val != this.coupon.COUPONCODE) {
        this.coupon.COUPONCODE = val;
        this.coupon.isDisabledCoupon = true;
      }
    },
    "form.PAYTYPEID"(val) {
      if (this.onlyShow) {
        return;
      }
      //this.form.SENDINGTYPEID = null;

      this.form.PACKAGEID = null;
      let findedBuyerPaid = undefined;
      if (val) {
        findedBuyerPaid = this.lookup.payTypeData.find((item) => {
          return item.ID == val && item.ISBBUYERPAID.toString() == "1";
        });
      }
      this.lookup.sendingTypeData = this.lookup.originalSendingTypeData;
      this.lookup.packageData = this.lookup.originalPackageData;
      if (val && this.form.USEANOTHERINVOICE && this.contract.anotherInvoiceFindingContract) {
        this.lookup.sendingTypeData =
          this.contract.anotherInvoiceContractData.sendingtypes;
        this.lookup.packageData = this.contract.anotherInvoiceContractData.packages;
      }
      else if (val && this.contract.senderFindingContract) {
        this.lookup.sendingTypeData =
          this.contract.senderContractData.sendingtypes;
        this.lookup.packageData = this.contract.senderContractData.packages;
      }
      else {
        if (val && this.contract.takerFindingContract) {
          if (findedBuyerPaid) {
            this.lookup.sendingTypeData =
              this.contract.takerContractData.sendingtypes;
            this.lookup.packageData = this.contract.takerContractData.packages;
          } /* else {
                      if (!findedBuyerPaid && this.contract.senderFindingContract) {
                        this.lookup.sendingTypeData = this.contract.senderContractData.sendingtypes;
                        this.options.sendingtype = this.contract.senderContractData.sendingtypes
                          .filter((item) => {
                            if (findedBuyerPaid) {
                              return true;
                            } else {
                              return item.ISSTANDARTSENDING == "1";
                            }
                          })
                          .map((item) => {
                            return {
                              text: item.SENDINGTYPENAME,
                              value: item.ID,
                            };
                          });
                        this.options.sendingtype.unshift({
                          text: this.$t("form.selectsendingtype"),
                          value: null,
                          disabled: true,
                        });
                        this.lookup.packageData = this.contract.senderContractData.packages;
                        this.options.package = this.contract.senderContractData.packages.map(
                          (item) => {
                            return {
                              text: item.PACKAGENAME,
                              value: item.ID,
                            };
                          }
                        );
                        this.options.package.unshift({
                          text: this.$t("form.selectpackage"),
                          value: null,
                          disabled: true,
                        });
                      } else if (
                        findedBuyerPaid &&
                        this.contract.senderFindingContract &&
                        this.isBrokerage
                      ) {
                        this.lookup.sendingTypeData = this.contract.senderContractData.sendingtypes;
                        this.options.sendingtype = this.contract.senderContractData.sendingtypes
                          .filter((item) => {
                            if (findedBuyerPaid) {
                              return true;
                            } else {
                              return item.ISSTANDARTSENDING == "1";
                            }
                          })
                          .map((item) => {
                            return {
                              text: item.SENDINGTYPENAME,
                              value: item.ID,
                            };
                          });
                        this.options.sendingtype.unshift({
                          text: this.$t("form.selectsendingtype"),
                          value: null,
                          disabled: true,
                        });
                        this.lookup.packageData = this.contract.senderContractData.packages;
                        this.options.package = this.contract.senderContractData.packages.map(
                          (item) => {
                            return {
                              text: item.PACKAGENAME,
                              value: item.ID,
                            };
                          }
                        );
                        this.options.package.unshift({
                          text: this.$t("form.selectpackage"),
                          value: null,
                          disabled: true,
                        });
                      }
                      this.calcPrice();
                    }*/
        }
      }
      this.options.package = this.lookup.packageData.map((item) => {
        return {
          text: item.PACKAGENAME,
          value: item.ID,
        };
      });
      this.options.package.unshift({
        text: this.$t("form.selectpackage"),
        value: null,
        disabled: true,
      });
      this.options.sendingtype = this.lookup.sendingTypeData
        .filter((item) => {
          if (findedBuyerPaid) {
            return true;
          }
          else {
            return item.ISSTANDARTSENDING == "1";
          }
        })
        .map((item) => {
          return {
            text: item.SENDINGTYPENAME,
            value: item.ID,
          };
        });
      this.options.sendingtype.unshift({
        text: this.$t("form.selectsendingtype"),
        value: null,
        disabled: true,
      });
      this.calcPrice();
    },
    "contract.senderFindingContract"(val, oldVal) {
      /*this.isUseDiscountAll=false;
      if(val){
        this.isUseDiscountAll=this.contract.senderContractData.ISUSEDISCOUNTALL=='1'?true:false;
      }*/
      if (!val && oldVal) {
        this.form.PAYTYPEID = null;
      }
    },
    "contract.takerFindingContract"(val, oldVal) {
      /*
      this.isUseDiscountAll=false;
       if(val){
        this.isUseDiscountAll=this.contract.takerContractData.ISUSEDISCOUNTALL=='1'?true:false;
      }*/
      if (!val && oldVal) {
        this.form.PAYTYPEID = null;
      }
    },
    "contract.brokerFindingContract"(val, oldVal) {
      if (!val && oldVal) {
        this.form.PAYTYPEID = null;
      }
    },
    "contract.anotherInvoiceFindingContract"(val, oldVal) {
      if (!val && oldVal) {
        this.form.PAYTYPEID = null;
      }
    },
    "form.SENDINGTYPEID"(val) {
      if (this.onlyShow)
        return;
      this.activePaymentDoor = false;
      this.form.PAYMENTDOORPRICE = 0;
      this.lookup.sendingTypePrice = null;
      this.lookup.sendingTypeTaxRate = null;
      if (val) {
        const findedSendingTypePaymentDoor = this.lookup.sendingTypeData.find((item) => item.ID.toString() == val.toString() &&
          item.ISPAYMENTDOOR.toString() == "1");
        if (findedSendingTypePaymentDoor) {
          this.activePaymentDoor = true;
          this.lookup.sendingTypePrice =
            findedSendingTypePaymentDoor.SENDINGTYPEPRICE;
          this.lookup.sendingTypeTaxRate =
            findedSendingTypePaymentDoor.SENDINGTYPETAXRATE;
          this.form.PAYMENTDOORPRICE = parseFloat(parseFloat(this.lookup.sendingTypePrice)).toFixed(2);
        }
      }
    },

    "form.STATICPRICES"() {
      if (this.onlyShow)
        return;
      this.calcPrice();
    },

    isHaveContract(val) {
      const payTypeData = this.lookup.payTypeData;
      if (!val) {
        this.options.paytype = payTypeData
          .filter((item) => item.ISNONCASH == "0")
          .map((item) => {
            return {
              text: item.PAYTYPENAME,
              value: item.ID.toString(),
            };
          });
        this.options.paytype.unshift({
          text: this.$t("form.selectpaytype"),
          value: null,
          disabled: true,
        });
      }
      else {
        this.options.paytype = payTypeData.map((item) => {
          return {
            text: item.PAYTYPENAME,
            value: item.ID.toString(),
          };
        });
        this.options.paytype.unshift({
          text: this.$t("form.selectpaytype"),
          value: null,
          disabled: true,
        });
      }
    },
    isContractTakerPaymentType() {
      if (this.onlyShow)
        return;
      this.calcPrice();
    },
    "form.USEANOTHERINVOICE"(val) {
      if (!val) {
        this.selected.anotherInvoice = null;
        this.contract.anotherInvoiceFindingContract = false;
        this.contract.anotherInvoiceContractData = {};
      }
    },
    "selected.anotherInvoice"(val) {
      if (val) {
        if (val?.PRESENTID) {
          this.anotherInvoiceIsContract({ id: val.PRESENTID, type: "present" });
        }
      }
    },
    details: {
      deep: true,
      handler(val) {
        if (this.onlyShow)
          return;
        this.calcPrice();
      }
    }
  },
  components: { CargoDetail }
};
</script>

<style lang="scss" scoped>
.logo-area {
  position: absolute;
  bottom: 2px;
  left: 15px;

  a:hover {
    img {
      filter: grayscale(0%);
    }
  }

  img {
    height: 27px;
    margin-right: 55px;
    filter: grayscale(75%);
  }
}
</style>
